@media only screen and (max-width: 900px) {
  .wrapper {
    flex-direction: column;
    gap: 1.5rem
  }

  .wrapper a {
    margin-top: 1rem
  }

  .wrapper h1 {
    font-size: 2rem
  }
}

@media only screen and (min-width: 901px) {
  .wrapper {
    gap: 4rem;
  }

  .wrapper a {
    margin-top: 2rem;
  }

  .wrapper h1 {
    font-size: 3rem;
  }
}

.wrapper {
  display: flex;
  align-items: center;
}

.wrapper h1 {
  line-height: 1.8ch;
  margin-bottom: 0.5rem;
}

.wrapper img {
  border-radius: 10px;
  max-width: 100%;
  height: auto;
}

.wrapper a {
  display: block;
  color: white;
  background-color: var(--color-primary);
  padding: 0.5rem 1rem;
  border-radius: 3px;
  text-align: center;
}

.wrapper a:hover {
  background-color: var(--color-primary-dark);
}