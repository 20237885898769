.navbar {
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-bottom: 1.5rem;
}

.header {
  width: 100%;
  padding: 1rem 0;
  background-color: var(--color-primary);
  text-align: center;
  font-weight: 600;
  color: white;  
}

.header svg {
  margin: 0 0.5rem;
}

.nav-link-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 1.5rem;
  padding: 0.5rem 0;
  border-bottom: solid 1px #dbe2da;
  box-shadow: rgba(85, 94, 85, 0.15) 0px 2px 8px;
}

.nav-link-wrapper a:hover {
  text-decoration: underline solid 2px;
  cursor: pointer;
}