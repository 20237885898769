.header {
  margin-bottom: 2rem;
  text-align: center;
}

.items-grid {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  max-width: 800px;
  gap: 2rem;
  margin: auto;
}