@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@400;600&display=swap');

:root {
  --color-primary: #54744d;
  --color-primary-dark: #466141;
}

body {
  margin: 0;
  margin-bottom: 1.5rem;
  font-family: 'Poppins', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: rgb(117, 128, 116);
}

* {
  margin: 0;
  box-sizing: border-box;
}

a {
  text-decoration: none;
  color: currentColor;
}

button {
  font-family: inherit;
  cursor: pointer;
  border: none;
  border-radius: 3px;
  padding: 0.35rem;
  background-color: var(--color-primary);
  color: white;
}

button:hover {
  background-color: var(--color-primary-dark);
}

a:hover, button:hover {
  transition: background-color 80ms;
}

main {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 0 0.5rem;
}

h1, h2, h3 {
  color: var(--color-primary);
}

::selection {
  background-color: var(--color-primary);
  color: white;
}

::-webkit-scrollbar {
  width: 10px;
}

::-webkit-scrollbar-track {
  background: #f1f1f1;
}

::-webkit-scrollbar-thumb {
  background: var(--color-primary);
  border-radius: 100px;
  border: solid 2px #f1f1f1;
}

::-webkit-scrollbar-thumb:hover {
  background: var(--color-primary-dark);
}